import { Component, OnInit } from '@angular/core'
import { NgForm } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { AuthService } from '@services/auth.service'
import { I18n } from 'src/i18n/i18n'

@Component({
  selector: 'cropman-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private i18n: I18n,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.i18n.init(this.translate, 'pt_BR');    
    
  }

  async login(form: NgForm): Promise<void> {
    if (!form.valid) {
      return
    }

    const username = form.value.username
    const password = form.value.password

    await this.authService.login(username, password)
  }
}
