import { BrowserModule } from '@angular/platform-browser'
import { ErrorHandler, NgModule } from '@angular/core'
import { ProvidersModule } from '@withleaf/leaf-link-angular';
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { LoginComponent } from './login/login.component'
import {  MatInputModule } from '@angular/material/input'
import { FormsModule } from '@angular/forms'
import {  MatButtonModule } from '@angular/material/button'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { CommonModule, DatePipe } from '@angular/common'
import {  MatSnackBarModule } from '@angular/material/snack-bar'
import { AuthService } from '@services/auth.service'
import { AuthInterceptorService } from '@services/auth-interceptor.service'
import { ErrorHandlerService } from '@services/error-handler.service'
import { I18n } from 'src/i18n/i18n';


export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http)
}

@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatSnackBarModule,    
    ProvidersModule,    
    TranslateModule
  ],
  providers: [
    I18n,
    DatePipe,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
