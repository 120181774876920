import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { catchError, tap } from 'rxjs/operators'
import { AuthResponse } from '@models/auth_response'
import { EMPTY, Observable, throwError } from 'rxjs'
import { Router } from '@angular/router'
import { RefreshResponse } from '@models/refresh_response'

@Injectable({ providedIn: 'root' })
export class AuthService {
  urls = {
    token: '/api/token',
    token_refresh: '/api/token/refresh',
    token_revoke: '/api/token/revoke',
  }

  constructor(private httpClient: HttpClient, private router: Router) {}

  login(username: string, password: string): Promise<AuthResponse> {
    return this.httpClient
      .post(`${environment.backend}${this.urls.token}`, { username, password })
      .pipe(
        catchError(this.handleAuthError),
        tap((response: AuthResponse) => {
          this.handleAuth(response)
        })
      )
      .toPromise()
  }

  async logout(revokeToken = false): Promise<void> {
    if (revokeToken) {
      await this.revokeToken()
    }
    localStorage.removeItem('access')
    localStorage.removeItem('refresh')
    this.router.navigate(['login'])
  }

  getAccessToken(): string | null {
    return localStorage.getItem('access')
  }

  private handleAuth(authResponse: AuthResponse): void {
    localStorage.setItem('access', authResponse.access)
    localStorage.setItem('refresh', authResponse.refresh)
    this.router.navigate(['requests'])
  }

  private handleAuthError(errorResponse: HttpErrorResponse): Observable<any> {
    if (errorResponse.error.detail) {
      if (
        errorResponse.error.detail ===
        'No active account found with the given credentials'
      ) {
        return throwError('CREDENTIALS_ERROR')
      } else {
        return throwError(errorResponse.error.detail)
      }
    } else {
      return throwError('UNKNOWN_ERROR')
    }
  }

  refreshToken(): Observable<any> {
    const refresh = localStorage.getItem('refresh')
    if (refresh && refresh.length) {
      return this.httpClient
        .post(`${environment.backend}${this.urls.token_refresh}`, { refresh })
        .pipe(
          tap((response: AuthResponse) => {
            this.handleRefresh(response)
          })
        )
    }
  }

  private handleRefresh(refreshResponse: RefreshResponse): void {
    localStorage.setItem('access', refreshResponse.access)
  }

  private revokeToken(): Promise<any> {
    const refresh = localStorage.getItem('refresh')
    if (refresh && refresh.length) {
      return this.httpClient
        .post(`${environment.backend}${this.urls.token_revoke}`, { refresh })
        .pipe(catchError(() => EMPTY))
        .toPromise()
    }
  }
}
