<div class="login-page">
  <div class="login mat-app-background">
    <div class="login-header">
      <img class="logo" src="assets/img/cropman_logo.png" alt="Cropman" />
      <img class="sub-logo" src="assets/img/cropman_sub_logo.png" alt="Cropman" />
    </div>

    <form class="login-form" #authForm="ngForm" (ngSubmit)="login(authForm)">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'USERNAME' | translate }}</mat-label>
        <input matInput id="username" name="username" ngModel required />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'PASSWORD' | translate }}</mat-label>
        <input matInput id="password" name="password" type="password" ngModel required />
      </mat-form-field>
      <span class="forgot-password">{{ 'FORGOT_PASSWORD' | translate }}</span>
      <button class="submit-button" mat-raised-button color="primary">
        {{ 'LOGIN' | translate | uppercase }}
      </button>
    </form>

    <div class="footer">
      <span>Disclaimer</span>
    </div>
  </div>

  <div class="login-bg"></div>
</div>
