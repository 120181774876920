import { ErrorHandler, Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { environment } from '@env'
import { TranslateService } from '@ngx-translate/core'

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService implements ErrorHandler {
  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  handleError(error: Error): void {
    if (error.message && error.message.indexOf(':')) {
      const labelError = error.message.split(':').pop()
      this.snackBar.open(this.translateService.instant(labelError.trim()), 'x')
    } else {
      this.snackBar.open(this.translateService.instant('UNKNOWN_ERROR'), 'x')
    }
    if (!environment.production) {
      console.error(error)
    }
  }
}
