import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { en_Us } from './locales/en_US/translation';
import { pt_BR } from './locales/pt_BR/translation';

@Injectable({
  providedIn: 'root',
})
export class I18n {
  constructor(private translate: TranslateService) {}

  init(componentTranslation: TranslateService, lang: string) {
    const availableLangs = ['en_US', 'pt_BR'];
    const fallbackLang = 'en_US';
    const browserLang =
      this.translate.getBrowserCultureLang()?.replace('-', '_') ?? fallbackLang;

    this.translate.setDefaultLang(fallbackLang);

    const selectedLang = availableLangs.includes(lang) ? lang : undefined;
    const navigatorLang = availableLangs.includes(browserLang)
      ? browserLang
      : fallbackLang;

    this.changeLanguage(selectedLang ?? navigatorLang);

    componentTranslation.setTranslation('en_US', en_Us);
    componentTranslation.setTranslation('pt_BR', pt_BR);
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
  }
}
