export const environment = {
  production: false,
  backend: 'https://portal.cropman.com.br'
}

// Satélite
//export const environment = {
//  production: false,
//  backend: 'http://satelite.homologando.agr.br:8023',
//}

// Homolog
//export const environment = {
//  production: false,
//  backend: 'https://cropman.homologando.agr.br',
//}
