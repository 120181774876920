import { Component } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ChangeDetectorRef } from '@angular/core';
import { I18n } from '../i18n/i18n';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  // constructor(translateService: TranslateService) {
  //   translateService.setDefaultLang('pt_BR')
  //   translateService.use('pt_BR')
  // }

  constructor(
    private i18n: I18n,
    private translate: TranslateService,
    private cdref: ChangeDetectorRef
  ) {}
  
  ngOnInit(): void {
    this.i18n.init(this.translate, 'pt_BR');
    this.cdref.detectChanges();    
  }
}

