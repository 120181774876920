import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { defer, from, Observable, throwError } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'
import { AuthService } from './auth.service'

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.auth.getAccessToken()

    if (!token) {
      return next.handle(request)
    }

    const authHandle = defer(() => {
      const authorizedReq = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.getAccessToken()}`,
        },
      })
      return next.handle(authorizedReq)
    })

    const self = this
    return authHandle.pipe(
      catchError((requestError, retryRequest) => {
        if (
          requestError instanceof HttpErrorResponse &&
          requestError.status === 401
        ) {
          if (requestError.url.endsWith('/api/token/refresh')) {
            this.auth.logout().then(() => {
              return throwError('SESSION_EXPIRED_ERROR')
            })
          }
          return from(self.auth.refreshToken()).pipe(
            mergeMap(() => retryRequest)
          )
        } else {
          return throwError('UNKNOWN_ERROR')
        }
      })
    )
  }
}
