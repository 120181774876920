export const pt_BR = {
  INFO_FILTER_HEADER: "Filtre as solicitações por fazenda(s)",
  INFO_REQUEST_HEADER: "Acompanhe suas solicitações",
  ACTION_REQUEST_HEADER: "Clique sobre uma solicitação para ver detalhes",
  ACTIVE: "Ativo",
  ADMIN: "Administrador",
  AREA_SHAPE: "Contorno da Área",
  CANCEL: "Cancelar",
  CENTER_LAYER: "Centralizar Camada",
  CLEAR: "Limpar",
  CLIENT: "Cliente",
  CLIENTS: "Clientes",
  CREATED_BY: "Criado por",
  CREATED_ON: "Criada em",
  CREDENTIALS_ERROR: "Erro de autenticação",
  DATA: "Dados",
  DATE: "Data",
  DOWNLOAD_FILE: "Baixar Arquivo",
  EMAIL: "E-Mail",
  FARM: "Fazenda",
  FIELD: "Talhão",
  FILES: "Arquivos",
  FILTER: "Filtrar",
  FILTER_FARMS: "Filtrar Fazendas",
  FORGOT_PASSWORD: "Esqueceu a senha ?",
  GO_BACK: "Voltar",
  HIDE_LAYER: "Esconder Camada",
  INFO: "Informações",
  // INFO_FILTER_HEADER: "Filtre as solicitações por fazenda(s)",
  // INFO_REQUEST_HEADER: "Acompanhe suas solicitações",
  LOGIN: "Login",
  LOGOUT: "Sair",
  NAME: "Nome",
  NO_FILES_FOUND: "Nenhum Arquivo Encontrado",
  NO_REQUESTS_FOUND: "Nenhuma Ordem de Serviço encontrada",
  NO_REQUESTS_FOUND_FILTER: "Nenhuma Ordem de Serviço encontrada com os filtros ativos",
  OS_NUMBER: "Número da Ordem de Serviço",
  OWNER: "Proprietário",
  PASSWORD: "Senha",
  PERMISSION_ERROR: "Você não possui as permissões necessárias para acessar este recurso",
  PHONE_NUMBER: "Telefone",
  PLOT: "Talhão",
  REQUEST: "Solicitação",
  REQUESTED_SERVICES: "Serviço(s) Solicitado(s)",
  REQUESTS: "Solicitações",
  RESULTING_DOCUMENT: "Documento Resultante",
  RESULTING_LAYER: "Zonas de Manejo",
  AMPLING_POINTS: "Pontos Amostrais",
  SEARCH: "Pesquisar",
  SEARCH_FARMS: "Pesquisar Fazenda",
  STAFF: "Funcionário",
  STATUS: "Status",
  SYSTEM_SEARCH_PLACEHOLDER: "Número ou Fazenda",
  TABLE_SAMPLES: "Tabela de Amostras",
  UNKNOWN_ERROR: "Algo aconteceu. Tente novamente mais tarde.",
  USERNAME: "Nome de Usuário",
  USERS: "Usuários",
  USERS_SEARCH_PLACEHOLDER: "Nome ou Organização",
  VIEW_LAYER: "Visualizar Camada",
  YEAR: "Ano",
  ca: "Cálcio (ca)",
  satb: "Sat. Bases (V%)",
  p: "Fósforo (p)",
  al: "Alúminio (al)",
  argila: "Argila",
  b: "Boro (b)",
  ctc: "CTC",
  ctce: "CTCE",
  cu: "Cobre (cu)",
  fe: "Ferro (fe)",
  hal: "Hal",
  k: "Potássio (k)",
  mg: "Magnésio (mg)",
  mn: "Manganês (mn)",
  mo: "Matéria Orgânica",
  phcacl2: "pH CaCl2",
  s: "Enxofre (s)",
  satal: "Sat. Alúminio",
  satca: "Sat. Cálcio",
  satk: "Sat. Potássio",
  satmg: "Sat. Magnésio",
  zn: "Zinco (zn)",
  sb: "SB",
  mo_2: "Matéria Orgânica (2)",
  ctc_2: "CTC (2)",
  ctce_2: "CTCE (2)",
  phcacl2_2: "pH CaCl2 (2)",
  ca_2: "Cálcio (2)",
  satca_2: "Sat. Cálcio (2)",
  mg_2: "Magnésio (2)",
  satmg_2: "Sat. Magnésio (2)",
  k_2: "Potássio (2)",
  satk_2: "Sat. Potássio (2)",
  p_2: "Fósforo (2)",
  satb_2: "Sat. Bases V% (2)",
  al_2: "Alumínio (2)",
  satal_2: "Sat. Alumínio (2)",
  s_2: "Enxofre (2)",
  hal_2: "Hal (2)",
  sb_2: "SB (2)",
  phh2o: "pH H₂0",
  phh2o_2: "pH H₂0 (2)",
  c: "Carbono Orgânico",
  c_2: "Carbono Orgânico (2)",
  ctca: "CTC Argila",
  ctca_2: "CTC Argila (2)",
  dif: "Diferença",
  ninfas: "Ninfas Metro",
  nemat: "Nematóides",
  silte: "Silte",
  areia: "Areia"
}