import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService  {
  constructor(public router: Router) {}

  canActivate(): boolean {
    const session = localStorage.getItem('access')
    if (!session || !session.length) {
      this.router.navigate(['login'])
      return false
    }
    return true
  }
}
